<template>
  <div class="main-content-boxed" id="page-container">
    <main id="main-container">
      <div
        class="bg-image"
        style="background-image: url('/static/media/photos/photo34@2x.jpg')"
      >
        <div class="row mx-0 bg-earth-op">
          <div
            class="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end"
          >
            <div class="p-4">
              <p class="fs-3 fw-semibold text-white mb-1">
                We're very happy you are joining our!
              </p>
              <p class="fs-5 text-white fw-medium">
                <i class="fa fa-angle-right opacity-50"></i> Create your account
                today !
              </p>
              <p class="text-white-75 fw-medium">
                Copyright &copy; <span data-toggle="year-copy">2022</span>
              </p>
            </div>
          </div>
          <div
            class="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-body-extra-light"
          >
            <div class="content content-full">
              <div class="px-4 py-2 mb-4">
                <a class="link-fx fw-bold" href="/public">
                  <i class="fa fa-fire"></i>
                  <span class="fs-4 text-body-color">宥马-Tool</span>
                </a>
                <h1 class="h3 fw-bold mt-4 mb-2">创建新账户</h1>
                <h2 class="h5 fw-medium text-muted mb-0">请填写您的详细资料</h2>
              </div>
              <form
                class="js-validation-reminder px-4"
                onsubmit="return false;"
              >
                <div class="form-floating mb-4">
                  <input
                    class="form-control"
                    id="username"
                    v-model="form.username"
                    placeholder="输入你的账号"
                    type="text"
                  />
                  <label class="form-label" for="username">账号</label>
                </div>
                <div class="form-floating mb-4">
                  <input
                    class="form-control"
                    id="password"
                    v-model="form.password"
                    placeholder="输入你的密码"
                    type="password"
                  />
                  <label class="form-label" for="password">密码</label>
                </div>
                <div class="form-floating mb-4">
                  <input
                    class="form-control"
                    id="qq"
                    v-model="form.qq"
                    placeholder="输入你的QQ"
                    type="number"
                  />
                  <label class="form-label" for="qq">QQ</label>
                </div>
                <div class="row mb-4">
                  <div class="col-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="captcha"
                        v-model="form.captcha"
                        placeholder="请输入验证码"
                      />
                      <label class="form-label" for="captcha">验证码</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-floating">
                      <div>
                        <img
                          class="border"
                          id="captcha_img"
                          :src="captcha"
                          @click="getVerify()"
                          alt="captcha"
                          style="height: 58px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="form-check">
                    <input
                      checked
                      class="form-check-input"
                      id="signup-terms"
                      name="signup-terms"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="signup-terms"
                      >我同意条款</label
                    >
                  </div>
                </div>
                <div class="mb-4">
                  <button
                    class="btn btn-lg btn-alt-primary fw-semibold"
                    type="submit"
                    @click="register"
                  >
                    创建账户
                  </button>
                  <div class="mt-4">
                    <a
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      @click="$router.push('/login')"
                    >
                      返回登录
                    </a>
                    <a
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      @click="$router.push('/find')"
                    >
                      找回密码
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { getCaptcha, register } from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      form: reactive({
        username: null,
        password: null,
        qq: null,
        uuid: null,
        captcha: null,
        remember: true,
      }),

      captcha: ref(""),
    };
  },
  created() {
    this.getVerify();
  },

  methods: {
    register() {
      if (this.form.username == null || this.form.password == null) {
        this.$message.warning("账号或密码不能为空");
        return false;
      } else if (this.form.captcha == null) {
        this.$message.warning("验证码不能为空");
        return false;
      } else if (this.form.qq == null) {
        this.$message.warning("QQ号不能为空");
        return false;
      }

      register(this.form)
        .then((message) => {
          this.$message.success(message);

          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.getVerify();
        });
    },
    getVerify() {
      getCaptcha().then((res) => {
        this.captcha = "data:image/jpeg;base64," + res.code;
        this.form.uuid = res.uuid;
      });
    },
  },
};
</script>

<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 68px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}
</style>
